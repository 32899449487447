import { ScrollView, View } from 'react-native';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

import type { Props as TileCardProps } from './cards/TileCard';
import TileCard, { TileCardVariant } from './cards/TileCard';
import useUIContext from './hooks/useUIContext';
import Stack from './Stack';
import Text from './Text';

export { TileCardProps, TileCardVariant };

export type Props = {
	title?: string;
	content: TileCardProps[];
	tileSize?: number;
	tileAspectRatio?: number;
};

const TileCardCarousel = ({
	title,
	content,
	tileSize,
	tileAspectRatio,
}: Props) => {
	const { isWeb } = useUIContext();
	const {
		styles,
		theme: { spacing },
	} = useStyles(stylesheet);
	return (
		<Stack style={styles.root} spacing={spacing.sm}>
			<View style={styles.titleContainer}>
				{!!title && <Text variant="label_md">{title}</Text>}
			</View>
			<View style={styles.tilesWrap}>
				<ScrollView horizontal showsHorizontalScrollIndicator={isWeb}>
					<View style={styles.tiles}>
						{content && content.length > 0 ? (
							content.map((tileContent, i) => (
								<TileCard
									key={i}
									tileSize={tileSize}
									tileAspectRatio={tileAspectRatio}
									{...tileContent}
								/>
							))
						) : (
							<View style={styles.noContent}>
								<Text>No content</Text>
							</View>
						)}
					</View>
				</ScrollView>
			</View>
		</Stack>
	);
};

const stylesheet = createStyleSheet(({ spacing }) => ({
	root: {
		width: '100%',
	},
	tilesWrap: {
		marginHorizontal: -spacing.md,
		// Account for scrollbar on web
		marginBottom: -spacing.xs,
	},
	tiles: {
		flexDirection: 'row',
		paddingHorizontal: spacing.md,
		// Account for scrollbar on web
		paddingBottom: spacing.xs,
	},
	noContent: {
		opacity: 0.5,
	},
	titleContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	unlockedContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		gap: spacing.xs2,
	},
}));

export default TileCardCarousel;
